import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import {BiMedal} from "react-icons/bi";


const Blockinfo = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
       <>
       <div className='items-center bg1 text-center p-7 mx-8 rounded-b-lg flex-wrap flex gap-4 justify-center mb-5'>
            <span className='bg2 p-5 rounded flex gap-2 font-bold text-2xl'><BiMedal className='text-2xl'/>{rpdata?.dbSlogan?.[7]?.slogan}</span>
            <span className='bg2 p-5 rounded flex gap-2 font-bold text-2xl'><BiMedal className='text-2xl'/>{rpdata?.dbSlogan?.[8]?.slogan}</span>
            <span className='bg2 p-5 rounded flex gap-2 font-bold text-2xl'><BiMedal className='text-2xl'/>{rpdata?.dbSlogan?.[9]?.slogan}</span>
       </div>
       </>
    )
}

export default Blockinfo